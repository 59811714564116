.tpl-home .projects-categories :--heading {
  display: none;
}

/* .tpl-home .projects-categories a {
  pointer-events: none;
  cursor: default;
  outline: none;
}

.tpl-home .projects-categories a:focus,
.tpl-home .projects-categories a:active {
  outline: none;
  box-shadow: none;
} */

.projects-categories a {
  display: block;
}
