.projects-list :is(ol, ul) {
  grid-gap: 1rem;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @media (--viewport-medium) {
    grid-gap: 2rem;
    /* grid-template-columns: 1fr 1fr 1fr; */
  }

  /* @media (--viewport-large) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (--viewport-extra-large) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  } */
}

.projects-list-item--large {
  grid-column: span 2;
  grid-row: span 1;

  @media (--viewport-medium) {
    grid-row: span 2;
  }
}

.projects-list :is(ol, ul) {
  @mixin uiList;
}

.projects-list-item a {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.projects-list-item-cover {
  /* flex: 0; */

  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.projects-list-item--large .projects-list-item-cover {
  flex: 1;
}

.projects-list-item-text {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.projects-list-item-title {
  margin-top: calc(1 * var(--flow-space));
  margin-bottom: 0;
  font-size: var(--size-400);
}

.projects-list-item-subtitle {
  margin-top: 0;
  display: none;
  line-height: 1.3;
}
.projects-list-item-category {
  margin-top: 0;
  margin-bottom: 0;

  color: var(--color-text);
  @mixin colorTextLowContrast;
}

/* Disabled */

.projects-list-item-cover {
  display: block;
  border-radius: 2px;
  overflow: hidden;
  @mixin boxShadow;
  transition: box-shadow 0.2s, transform 0.2s, background 1s, color 0s !important;
}
