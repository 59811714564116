@import "../../node_modules/modern-normalize/modern-normalize.css";
@import "./../js/components/scroll-snap-carousel/css/carousels.css";

@import "vars.css";
@import "global.css";
/* @import "fonts.css"; */
@import "typography.css";

@import "colors-light.css";
/* @import "colors-dark.css"; */

@import "header.css";
@import "footer.css";

@import "layout.css";
@import "page.css";

@import "home.css";
@import "project.css";
@import "projects.css";
@import "projects-list.css";
@import "projects-table.css";
@import "projects-navigation.css";
@import "breadcrumb.css";

@import "profile.css";
