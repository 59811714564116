:root {
  --content-width: 74rem;
  --outer-padding: var(--outer-gutters);
}

html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.site-content {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}

.wrap, .main {
  padding-left: var(--outer-padding);
  padding-right: var(--outer-padding);
}

.tpl-project .main {
  padding-left: 0;
  padding-right: 0;
}

/* .site-header, .site-content {
  max-width: 74rem;
  margin-left: auto;
  margin-right: auto;
} */

@media (--viewport-large) {
  :root {
    --outer-padding: calc(var(--outer-gutters) * 2);
  }
}

@media (--viewport-extra-large) {
  :root {
    --outer-padding: calc(var(--outer-gutters) * 2);
  }
}

@media (--viewport-extra-extra-large) {
  :root {
    --outer-padding: calc(var(--outer-gutters) * 4);
  }
}

@media (--viewport-large) {
  .wrap,
  body {
    --content-width: var(--bp-large);
  }
}

@media (--viewport-extra-large) {
  .wrap,
  body {
    --content-width: var(--bp-extra-large) - 2rem;
    --outer-padding: calc(
      (100vw - var(--content-width)) / 2 + var(--outer-gutters) * 6
    );
  }
}

/* === LAYOUT === */

@define-mixin grid {
  --gutter: 0;
  width: 100%;
  display: grid;
  grid-column-gap: var(--gutter);
  grid-template-columns: 1fr;

  @media (--viewport-large) {
    grid-template-columns: repeat(24, 1fr);
  }
}

@define-mixin outerGrid {
  --gutter: 2rem;
  display: grid;
  width: 100%;

  grid-column-gap: var(--gutter);
  grid-template-columns: 1fr;

  @media (--viewport-large) {
    grid-template-columns: repeat(12, 1fr);
  }
}

.grid {
  /* @mixin grid; */
}

/* Apply Grid */

.site-header {
  @mixin outerGrid;
}

@media (--viewport-large) {
  .site-header .logo {
    grid-column: 1 / span 4;
  }

  .site-header .main-navigation {
    grid-column: 5 / span 8;
  }
}

.main {
  @mixin outerGrid;
}

@media (--viewport-large) {
  .main-content {
    grid-column: 5 / span 8;
  }

  .project-content {
    grid-column: 1 / -1;
  }
}
