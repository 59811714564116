.breadcrumb ol {
  @mixin uiList;
  font-size: var(--size-300);
  margin-bottom: 1rem;
  display: flex;

  & li:first-child {
    display: none;
  }

  & li:last-child {
    /* display: none; */

    & a {
      font-weight: normal;
      opacity: 0.5;
    }
  }

  & li {
    margin-right: 1.5rem;
    position: relative;
  }

  & li::after {
    content: "";
    position: absolute;
    top: 0.1rem;
    right: -0.75rem;
    width: 1px;
    height: 1rem;
    background: var(--color-text);
    opacity: 0.3;
    transform: rotate(10deg);
  }

  & li:last-child::after {
    display: none;
  }

  & a {
    text-decoration: none;
  }

  & a:hover {
    text-decoration: underline;
  }
}
