@define-mixin uiList {
  padding-left: 0;
  list-style-type: none;
}

@define-mixin unsetButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

a:not([class]) {
  /* color: currentColor; */
  color: var(--color-text);
}

:focus {
  /* outline: 2px dashed;
  outline-offset: 0.25rem; */
}

/* a:active, .menu a:active {
  color: red;
} */

main:focus {
  outline: none;
}

[href]:hover {
  text-decoration: none;
}

img {
  /* max-width: min(55rem, 100%); */
  max-width: 100%;
  vertical-align: bottom;
}

figcaption {
  & p {
    margin-top: 0.5rem;
    font-size: var(--size-300);
    font-style: italic;
    text-align: right;
    max-width: none;
    @mixin colorTextLowContrast;
  }
}

blockquote {
  padding-left: var(--size-600);
  border-left: 5px solid;
  font-style: italic;
}

::selection {
  color: #fff;
  background: rgba(0, 0, 0, 0.99);
}

body {
  position: relative;
}

.a11y-nav {
  position: absolute;
  top: -20em;
  left: 0;
  right: 0;
  /* width: 100%; */
  z-index: 1;
}

.a11y-nav a:focus,
.a11y-nav a:hover {
  /* box-shadow: 3px 3px 5px #eee; */
  text-decoration: none;
  top: 20em;
}

.a11y-nav a {
  background-color: var(--color-bg-default);

  font-weight: 700;
  left: 0;
  padding: 12px;
  position: absolute;
  right: 0;
  text-align: center;
}

/* mixins */
@define-mixin boxShadow {
  box-shadow: 0 1.3rem 0.75rem -1rem hsl(0deg 0% 0% / 40%);

  &:hover {
    box-shadow: 0 0.75rem 0.5rem -0.6rem hsl(0deg 0% 0% / 65%);
    transform: translateY(4px);
  }
}
