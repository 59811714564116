.main {
  padding-top: 2rem;
  padding-bottom: 4rem;

  @media (--viewport-medium) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.projects-header {
  display: flex;
  justify-content: space-between;
}

.projects-header :is(:--heading, ol) {
  margin-top: 0;
  margin-bottom: 0;
}