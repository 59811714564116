:root {
  --hue: 0deg;
  --lightness: 50%;
  --color-text: hsl(var(--hue) 0% 0% / 90%);
  --color-text-lowcontrast: hsl(var(--hue) 0% 0% / 60%);
  --color-bg-default: hsl(var(--hue) 85% var(--lightness));
}

@define-mixin colorTextLowContrast {
  opacity: 0.8;
}

body {
  background-color: var(--color-bg-default);
  color: var(--color-text);
}

.projects-categories li,
.projects-list-item-cover,
.project-gallery-item {
  background-color: var(--color-bg-default);
}

.projects-list-item-cover img,
.vita img {
  mix-blend-mode: multiply;
  filter: contrast(120%) brightness(120%) saturate(0%);
}

.bgcover {
  content: "";
  display: block;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--color-bg-default);
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center;
}
