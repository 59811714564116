.projects-navigation-list {
  display: flex;
  justify-content: space-between;
}

.projects-navigation-el > a {
  display: block;
  padding: 0.75rem;
  margin: 0 -0.75rem;
}

@media (hover: hover) and (pointer: fine) {
  .projects-navigation-el:hover {
    & polygon {
      fill: var(--color-text);
    }
  }
}

.projects-navigation-el-icon svg {
  vertical-align: bottom;

  & polygon {
    fill: var(--color-text);
    @mixin colorTextLowContrast;
  }
}

.projects-navigation-prev svg {
  transform: rotate(180deg);
}

@media (--viewport-medium) {
  .projects-navigation-next:hover .projects-navigation-el-icon {
    transform: translateX(0.25rem);
  }

  .projects-navigation-prev:hover .projects-navigation-el-icon {
    transform: translateX(-0.25rem);
  }

  .projects-navigation-el-icon,
  .projects-navigation-el-icon polygon {
    transition: 0.2s;
  }
}
