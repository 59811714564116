/* Table */

.tag,
.category {
  font-size: var(--size-300);
  position: relative;
  padding-left: 0.6rem;
  margin-right: 0.6rem;
  white-space: nowrap;

  &::before {
    content: "";
    width: 4px;
    height: 4px;
    background: currentColor;
    position: absolute;
    top: calc(50% - 1px);
    left: 0rem;
  }
}

.tag {
  color: var(--color-text-lowcontrast);
}

.th-title {
  font-weight: bold;
}

[role="region"][aria-labelledby][tabindex] {
  overflow: auto;
}

[role="region"][aria-labelledby][tabindex]:focus {
  /* outline: 0.1em solid rgba(0, 0, 0, 0.1); */
  outline-style: solid;
}

.projects-table td:nth-child(4),
.projects-table td:nth-child(5) {
  white-space: nowrap;
}
.projects-table td:nth-child(5),
.projects-table thead th:nth-child(5) {
  text-align: right;
}

.projects-table table {
  font-size: var(--size-400);
  border-spacing: 0rem;
  text-align: left;

  & td,
  th {
    font-weight: normal;
    padding: 1rem 1rem;
    box-shadow: inset 0 -1px 0 0 var(--color-line, #aaa);
    vertical-align: top;
  }
}

.projects-table thead {
  font-size: var(--size-300);
  color: var(--color-text-lowcontrast);
  letter-spacing: 0.03em;
}

.projects-table tbody {
  text-align: left;
}
