.site-footer {
  padding-top: 2rem;
  padding-bottom: 2rem;
  opacity: 0.5;
}

.site-footer ol {
  margin-top: 0;
}

@media (--viewport-large) {
  .site-footer {
    display: flex;
    justify-content: space-between;
  }
}

.colorSwitcher-container {
  display: none;
}
