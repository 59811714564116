html {
  scroll-behavior: smooth;
  font-size: 95%; /* 20px */

  @media (--viewport-large) {
    font-size: 115%;
  }
}

:root {
  /* Major Third 1.25 */

  --size-300: 0.8rem;
  --size-400: 1rem;
  --size-500: 1.25rem;
  --size-600: 1.563rem;
  --size-700: 2.441rem;
  --size-800: 3.052rem;
}

body {
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  /* font-family:"Neue Haas Unica Pro", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif; */
}

:--heading,
ol,
ul,
p,
dl {
  margin-top: 1rem;
  margin-bottom: 0;

  &:first-child {
    margin-top: 0;
  }
}

h1,
h2,
h3,
h4 {
  line-height: 1.3;
  font-weight: 500;
}

h1,
h2 {
  font-weight: 500;
}

strong,
b {
  font-weight: 500;
}

h1 {
  font-size: var(--size-500);
}

h2 {
  font-size: var(--size-400);
}

h3 {
  font-size: var(--size-400);
}

h1 {
  max-width: 35ch;
}

.text :is(h2, h3) {
  max-width: 25ch;
}

.text :is(p, li) {
  max-width: 60ch;
}

.page-title {
  font-size: var(--size-600);
  margin-top: 0;
}

.projects-header,
.page-title {
  margin-bottom: calc(2 * var(--flow-space));
  @media (--viewport-medium) {
    margin-bottom: calc(4 * var(--flow-space));
  }
}

/* Flow */

.flow {
  --flow-space: 0.75rem;
}

.flow > * + * {
  margin-top: var(--flow-space, 1em);
}

.flow :--heading:not(.page-title) {
  margin-top: calc(2 * var(--flow-space));
}
