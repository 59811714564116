.site-header {
  padding-top: 2rem;
  display: grid;
  grid-gap: 1rem;
}

.site-title {
  color: currentColor;
  color: var(--color-text);
  text-decoration: none;
  display: inline-block;

  & span {
    /* color: var(--color-text-lowcontrast); */
    @mixin colorTextLowContrast;
    display: block;
  }
}

@media (--viewport-large) {
  .site-title span {
    text-align: right;
  }
}

.site-title b {
  text-transform: uppercase;
  font-size: var(--size-500);
  letter-spacing: 0.025em;
  font-weight: bold;
}

.site-title i {
  font-style: normal;
  font-size: 130%;
  /* color: hsla(0deg 0% 50% / 100%); */
  @mixin colorTextLowContrast;
  opacity: 0.75;
}

[aria-current] {
  font-weight: bold;
}

.menu {
  display: flex;
  & li {
    margin-right: 1rem;
  }
}

.menu {
  @mixin uiList;
  display: flex;
  align-items: flex-end;
}

.menu a {
  @mixin colorTextLowContrast;
  text-decoration: none;
  display: block;
  padding: 0.5rem;
  margin: -0.5rem;

  &:hover {
    color: var(--color-text);
  }
}

.menu [aria-current] {
  color: var(--color-text);
}

@media (--viewport-large) {
  .site-header {
    padding-top: 3rem;
  }

  .main-navigation {
    display: flex;
  }

  .main-navigation li {
    margin-right: 2rem;
  }
}
