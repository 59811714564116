.projects-categories :is(ol, ul) {
  @mixin uiList;
  grid-gap: 1rem;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @media (--viewport-medium) {
    grid-gap: 2rem;
    /* grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr)); */
    grid-template-columns: 1fr 1fr;
  }
}

.projects-categories a {
  text-decoration: none;
}