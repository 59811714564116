:root {
  --outer-gutters: 1rem;

  @media (--viewport-medium) {
    --outer-gutters: 1.5rem;
  }
}

:root {
  --flow-space: 0.75rem;
}

:root {
  --bp-small: 24rem;
  --bp-medium: 40rem;
  --bp-large: 62rem;
  --bp-extra-large: 78rem;
}

/* @media --scale: 1.X  */

@custom-media --viewport-smallest (width <= 24rem);
@custom-media --viewport-small (width > 24rem);
@custom-media --viewport-medium (width > 40rem);
@custom-media --viewport-large (width > 68rem);
@custom-media --viewport-extra-large (width > 78rem);
@custom-media --viewport-extra-extra-large (width > 94rem);

@custom-media --viewport-smallest-to-medium (width <= 40rem);
@custom-media --viewport-smallest-to-large (width <= 62rem);

@custom-selector :--heading h1, h2, h3, h4, h5, h6;

body::after {
  content: "";

  display: none;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  background: whitesmoke;
  color: #000;
  padding: 0.25rem;
  opacity: 0.5;

  @media (--viewport-smallest) {
    content: "--viewport-smallest";
  }

  @media (--viewport-small) {
    content: "--viewport-small";
  }

  @media (--viewport-medium) {
    content: "--viewport-medium";
  }

  @media (--viewport-large) {
    content: "--viewport-large";
  }

  @media (--viewport-extra-large) {
    content: "--viewport-extra-large";
  }

  @media (--viewport-extra-extra-large) {
    content: "--viewport-extra-extra-large";
  }
}
