.profile-team {
  @mixin uiList;
}

.profile-team-el {
  box-shadow: 0 1px 0 var(--color-text);

  &:last-child {
    box-shadow: none;
  }
}

.js .profile-team-el .vita {
  height: 0;
  overflow: hidden;
  transition: height 0.3s;
}

.profile-team-el {
  & h3 {
    position: relative;
  }

  & svg {
    width: 16px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 1rem;
    transition: transform 0.3s;
    pointer-events: none;
  }
}

.profile-team-el.has-meta h3:hover {
  /* background: hsl(0deg 0% 0% / 0.05); */
  text-decoration: underline;
}

.profile-team-el[data-visible] svg {
  transform: translateY(-50%) rotate(45deg);
}

[data-visible].profile-team-el .vita {
  /* height: auto; */
  /* overflow: visible; */
}

.vita-inner {
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.profile-team-el :--heading {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 0 !important;
  max-width: none;
}

.vita-photo-inner {
  margin-bottom: 1rem;
}

.profile-team-el :--heading path {
  fill: var(--color-text);
}
.js .profile-team-el.has-meta :--heading {
  cursor: pointer;
}

.vita-photo-inner {
  display: inline-block;
  background: var(--color-bg-default);
}

.js .vita-photo-inner {
  opacity: 0;
  transition: 0.3s;
}

@media (--viewport-large) {
  .vita-inner {
    position: relative;
  }

  .vita-text {
    min-height: 2rem;
  }

  .vita-photo {
    position: absolute;
    left: calc(-1 * var(--gutter));
    top: -3rem;
    pointer-events: none;
  }

  .vita-photo-inner {
    --gutter: 5rem;
    margin-left: calc(-100%);
    margin-right: 100%;
  }
}
