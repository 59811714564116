article.project {
  padding-bottom: 2rem;
}

/* header */

.project-header {
  --flow-space: 0.25rem;

  & .project-category {
    @mixin colorTextLowContrast;
    position: relative;
    display: inline-block;
  }

  & .page-title {
    margin-bottom: 0;
  }

  & .project-subtitle {
    @mixin colorTextLowContrast;
    display: none;
  }

  margin-bottom: 2rem;
}

/* Gallery */

@define-mixin fullWidth {
  width: calc(100vw);
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.project-gallery {
  width: 100vw;
}

.project-gallery-item {
  height: 20rem;
  flex: 1 0 80vw;
  scroll-snap-align: start;
}

.project-gallery-item figure {
  height: 100%;
  margin: 0 1rem 0 0;
  display: flex;
  flex-direction: column;

  &:last-child {
    margin-right: 0;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: none;
  }

  figcaption {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.project-gallery-item-image-container {
  flex: 1 1;
  min-height: 0;
}

@media (--viewport-medium) {
  .project-gallery-item {
    height: 30rem;
  }
}

@media (--viewport-large) {
  .project-gallery-item {
    flex-basis: 50rem;
    height: 35rem;
  }
}

/* Meta */

.project-meta {
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.project-meta dl {
  margin: 0;
}

.project-meta dt {
  font-weight: bold;
}
.project-meta dd {
  margin-left: 0;
  margin-bottom: 0;
  max-width: 44ch;
}

/* Layout Meta */

.project-meta dl {
  display: grid;
  grid-gap: 1rem 1rem;
}

@media (--viewport-medium) {
  .project-meta dl {
    grid-gap: 1rem 2rem;
    grid-template-columns: repeat(2, 1fr);
  }

  .project-meta-el--description {
    grid-column: 1 / -1;
  }
}

@media (--viewport-large) {
  .project-meta dl {
    /* grid-template-columns: repeat(4, minmax(min-content, 1fr)); */
    grid-template-columns: repeat(4, 1fr);
  }

  .project-meta-el--description {
    grid-column: 3 / -1;
    grid-row: 1 / span 2;
  }
}

.tpl-project .main {
  padding-bottom: 0;
}

.tpl-project .site-footer {
  box-shadow: none;
  margin-top: 2rem;
}

/* New Scroller Layout */

.carousel__track {
  @mixin uiList;
  transform: translate3d(0, 0, 0);
  padding-left: var(--outer-padding);
  padding-right: var(--outer-padding);
  scroll-padding-left: var(--outer-padding);
  align-items: center;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
}

.carousel__track > li {
  margin-right: 1rem;

  &:last-child {
    margin-right: 0;
  }
}

.carousel__track > li:last-child::after {
  position: relative;
  left: 100%;
  border: 1px solid transparent;
  display: block;
  content: "";
  width: var(--outer-padding);
}

.project-gallery-controls {
  position: relative;
  margin-bottom: 3rem;
}

.project-gallery-controls button {
  @mixin unsetButton;
  position: absolute;
  bottom: -2rem;
  transform: translateY(50%);
  z-index: 1;
  transition: opacity 0.2s;
  background: var(--color-text);
  color: var(--color-bg-default);
  width: 2.5rem;
  height: 2.5rem;
}

.project-gallery-controls button:disabled {
  opacity: 0;
  pointer-events: none;
}

[data-elem-next-button] {
  right: var(--outer-padding);
}

[data-elem-prev-button] {
  left: var(--outer-padding);
}

@media (pointer: coarse) {
  .project-gallery-controls {
    display: none;
  }
}
